import {
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { addItemCommand, addItemsCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import { useUiNotification } from '~/composables/useUiNotification';
import { useGtm } from '~/composables/useGtm';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
    CART,
    CART_ITEM,
    PRODUCT
    > {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { send: sendNotification } = useUiNotification();
  const {
    sendAddToCartEvent,
    sendRemoveFromCartEvent,
    getPromotionFromLocation,
    getItemListFromProduct,
  } = useGtm();

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, realCart = false } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, realCart }, app, sendNotification);
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      console.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery }, app, sendNotification);

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      console.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      if (err?.message === "Cart does not exist.") apiState.setCartId(); // Remove the cart if the cart doesn't exist
      error.value.loadTotalQty = err;
      console.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
                           product, quantity, productConfiguration, customQuery,
                         }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      if (!canAddToCart(product, quantity)) {
        Logger.debug("Product can't be added to cart, so not adding.");
        return;
      }

      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      const promotion = getPromotionFromLocation();
      const itemList = getItemListFromProduct(product);

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        promotion,
        itemList,
        customQuery,
      }, app.i18n);

      sendAddToCartEvent(product, quantity, promotion);

      if (Array.isArray(updatedCart)) {
        cartStore.$patch((state) => {
          // @ts-ignore
          state.cart = updatedCart[0];
        });
        throw updatedCart[1];
      } else {
        error.value.addItem = null;
        cartStore.$patch((state) => {
          state.cart = updatedCart;
        });
      }

    } catch (err) {
      sendNotification({
        icon: 'danger',
        id: Symbol(`product_out_of_stock`),
        message: String(err),
        persist: false,
        title: 'Product out of stock',
        type: 'danger',
      });
      error.value.addItem = err;
      console.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const addItems = async ({
                            products,
                            customQuery,
                          }: {
    products: Array<{
      sku: any,
      quantity: number,
      productType?: any,
    }>,
    customQuery?: any
  }): Promise<void> => {
    Logger.debug('useCart.addItems', { products });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      const promotion = getPromotionFromLocation();

      const cartItems = products.map(({ sku, quantity, productType }) => ({
        sku,
        quantity,
        promotion,
        productType
      }));

      const updatedCart = await addItemsCommand.execute(context, {
        currentCart: cart.value,
        cartItems,
        customQuery,
      }, app.i18n);

      if (Array.isArray(updatedCart)) {
        cartStore.$patch((state) => {
          // @ts-ignore
          state.cart = updatedCart[0];
        });
        throw updatedCart[1];
      } else {
        error.value.addItem = null;
        cartStore.$patch((state) => {
          state.cart = updatedCart;
        });

        sendNotification({
          id: Symbol('added_to_cart'),
          message: 'The items are added to the cart!' as string,
          persist: false,
          title: 'Abandoned cart items added to cart',
          type: 'success',
          icon: 'success',
        });
      }

    } catch (err) {
      sendNotification({
        icon: 'danger',
        id: Symbol(`product_out_of_stock`),
        message: String(err),
        persist: false,
        title: 'Product out of stock',
        type: 'danger',
      });
      error.value.addItem = err;
      Logger.error('useCart/addItems', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product: products[0].sku,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      const oldQuantity = cart.value.items.find(item => item.product.uid === product.product.uid)?.quantity ?? 0;
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
      });

      const promotion = getPromotionFromLocation();
      const itemList = product.item_list;
      sendRemoveFromCartEvent(product.product, oldQuantity, promotion, itemList);

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.removeItem = err;
      console.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        const oldQuantity = cart.value.items.find(item => item.product.uid === product.product.uid)?.quantity ?? 0;
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });
        const promotion = getPromotionFromLocation();
        const itemList = product.item_list;

        if (oldQuantity > quantity)
          sendRemoveFromCartEvent(product.product, oldQuantity - quantity, promotion, itemList);
        else if (quantity > oldQuantity)
          sendAddToCartEvent(product.product, quantity - oldQuantity, promotion, itemList);

        error.value.updateItemQty = null;

        // Don't throw null error when can't update item, instead notify user
        if (updatedCart !== undefined) {
          if (Array.isArray(updatedCart)) {
            cartStore.$patch((state) => {
              // @ts-ignore
              state.cart = updatedCart[0];
            });
            throw updatedCart[1];
          } else {
            cartStore.$patch((state) => {
              state.cart = updatedCart;
            });
          }
        }
      } catch (err) {
        console.log('ERROR', err)
        sendNotification({
          icon: 'danger',
          id: Symbol(`product_out_of_stock`),
          message: String(err),
          persist: false,
          title: 'Product out of stock',
          type: 'danger',
        });
        error.value.updateItemQty = err;
        console.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
        ? await applyCouponCommand.execute(context, variables)
        : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      console.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      console.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
          ?.uid;
    }
    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null
        ? true
        : qty <= product?.only_x_left_in_stock;

    const productUnavailable = product?.availability_code?.toLowerCase() === "n";

    return inStock && stockLeft && !productUnavailable;
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    // @ts-ignore
    addItems,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useCart;
export * from './useCart';
