import {
    ref,
    computed,
    watch,
    useRouter,
    useContext,
} from '@nuxtjs/composition-api';
import { defineStore, storeToRefs } from 'pinia';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import {
    OrderItemInterface,
    ProductInterface,
    PromotionInterface,
    ItemListInterface,
} from "~/modules/GraphQL/types";
import {Cart} from "~/modules/GraphQL/types";
import type { Customer } from '~/modules/GraphQL/types';

declare global {
    function gtag(...args: any): void;
    var dataLayer: any[]
}

type EcommerceProductItem = {
    item_id: string,
    item_name: string,
    affiliation?: string,
    coupon?: string,
    currency?: string,
    discount?: number,
    index?: number,
    item_brand?: string,
    item_category?: string,
    item_category2?: string,
    item_category3?: string,
    item_category4?: string,
    item_category5?: string,
    item_list_id?: string,
    item_list_name?: string,
    item_variant?: string,
    location_id?: string,
    price?: number,
    quantity?: number,
}

type EcommerceCartItem = {
    creative_name?: string
    creative_slot?: string
    promotion_name?: string
    promotion_id?: string
    item_list_name?: string
    item_list_id?: string
} & EcommerceProductItem;

type EcommerceOrderItem = EcommerceCartItem;

// GTM store is shared between any component/composable that uses it
// @ts-ignore
export const useGtm = defineStore('gtm', () => {
    // Backlog always waits for an INIT event first
    const eventsBacklog = ref(["INIT"]);
    const eventsBacklogLock = ref(false);

    const router = useRouter();

    const currentPageListItem = ref(null);
    const newPageListItem = ref(null);

    // Make sure we always keep track of the listItem from the previous page's select item event
    router.beforeEach((to, from, next) => {
        currentPageListItem.value = newPageListItem.value;
        newPageListItem.value = null;

        next();
    });

    const {
        $config,
        env,
        $cookies
    } = useContext();

    const DEBUG_GTM = computed(() => env["BOLD_GTM_DEBUG"] === "true" || parseInt(env["BOLD_GTM_DEBUG"]) === 1 || $cookies.get("bold-gtm-debug") === "true" || parseInt($cookies.get("bold-gtm-debug")) === 1);

    const gtmId = $config.gtmId;

    const scripts = [
        {
            hid: 'GTMScript',
            name: 'GTMScript',
            type: 'text/javascript',
            innerHTML: '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\',\'' + gtmId + '\');',
        },
        {
            hid: 'gtagScript',
            name: 'gtagScript',
            type: 'text/javascript',
            innerHTML: 'function gtag(){dataLayer.push(arguments);}'
        },
    ];

    const scriptsInjected = ref(false);

    /**
     * Watches for eventsBacklog changes
     * If there's a lock, return.
     * If the backlog is empty, return.
     * If the backlog starts with an INIT event, return.
     * Sets the lock to true to prevent other watchers from sending any events, if that's even possible.
     * Sends the first event from the backlog.
     * Removes the lock.
     * Removes the first item from the backlog, which should in theory trigger the watcher again, until the backlog is
     * entirely empty.
     */
    watch(eventsBacklog, () => {
        if (DEBUG_GTM.value) console.debug("EVENT BACKLOG WATCHER", structuredClone(eventsBacklog.value));
        if (eventsBacklogLock.value) return;
        if (eventsBacklog.value.length === 0) return;
        if (typeof eventsBacklog.value[0] === "string" && eventsBacklog.value[0].startsWith("INIT")) return;
        eventsBacklogLock.value = true;
        const event = eventsBacklog.value[0];
        sendEvent(event, true);
        eventsBacklogLock.value = false;
        eventsBacklog.value.shift();
    });

    /**
     * Pushes an INIT to the events backlog, which waits to be replaced by an init event before any further events are
     * pushed to the dataLayer.
     *
     * @param now
     */
    const waitForInit = (now: number) => {
        eventsBacklog.value.push(`INIT_${now}`);
    };

    /**
     * Removes a specific INIT event string from the event backlog, e.g. in case logging in failed due to some error
     *
     * @param now
     */
    const cleanupInitEvent = (now: number) => {
        const index = eventsBacklog.value.indexOf(`INIT_${now}`);
        if (index === -1) return;
        eventsBacklog.value.splice(index, 1);
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        // @ts-ignore
        const day = date.getDate().toString().padStart(2, "0");
        // @ts-ignore
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const formatPrice = price => parseFloat(parseFloat(price).toFixed(2))

    const normalizeValue = (value) => {
        if (!value) return "";
        return value.toString();
    }

    const getEcommerceItemCategories = (item, mainCategory?: string | null) => {
        const categories = {
            item_category: '',
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_category5: '',
        };
        const itemCategories = item.categories
          .sort((a, b) => {
              if (a.name === mainCategory) return -1;
              if (b.name === mainCategory) return 1;
              return 0;
          });
        if (itemCategories) {
            for (let i = 1; i <= Math.min(item.categories.length, 5); i++) {
                const propertyNameSuffix = i > 1 ? i : "";
                const propertyName = `item_category${propertyNameSuffix}`;
                categories[propertyName] = item.categories[i - 1].name;
            }
        }
        return categories;
    }

    /**
     * Constructs an object for prodocuts that will be used in events
     *
     * @param product
     * @param quantity
     */
    const getProductItemFromProduct = (product: ProductInterface, quantity: number, mainCategory?: string, itemList?): EcommerceProductItem => {
        const maximumPrice = productGetters.getPrice(product).maximum;
        const roundedMaximumPrice = parseFloat(maximumPrice.toFixed(2));

        let _itemList = itemList ?? {};

        const item: EcommerceProductItem = {
            item_id: product.sku,
            item_name: product.name,
            affiliation: '', // always empty?
            coupon: '', // always empty?
            currency: 'EUR',
            discount: 0, // TODO
            index: 0, // TODO
            item_brand: normalizeValue(product.mpbrand?.value),
            ...getEcommerceItemCategories(product, mainCategory),
            item_list_id: normalizeValue(product.item_list_id ?? _itemList.item_list_id),
            item_list_name: normalizeValue(product.item_list_name ?? _itemList.item_list_name),
            item_variant: '', //  TODO
            location_id: '', // TODO
            price: roundedMaximumPrice,
            quantity,
        };

        return item;
    }

    /**
     * Constructs an object that will be used in cart events in the items array
     *
     * @param product
     * @param quantity
     */
    const getCartEventItemFromProduct = (
        product: ProductInterface,
        quantity: number,
        promotion?: PromotionInterface,
        itemList?: ItemListInterface,
    ): EcommerceCartItem => {
        const productItem = getProductItemFromProduct(product, quantity);

        let _itemList = {};
        if (itemList)
            _itemList = {
                item_list_name: normalizeValue(itemList?.item_list_name),
                item_list_id: normalizeValue(itemList?.item_list_id),
            }

        const item: EcommerceCartItem = {
            ...productItem,
            creative_name: normalizeValue(promotion?.creative_name),
            creative_slot: normalizeValue(promotion?.creative_slot),
            promotion_name: normalizeValue(promotion?.promotion_name),
            promotion_id: normalizeValue(promotion?.promotion_id),
            ..._itemList,
        };

        return item;
    }

    /**
     * Constructs an object that will be used in purchase event in the items array
     *
     * @param orderItem
     */
    const getEcommerceOrderItemFromOrderItem = (orderItem: OrderItemInterface): EcommerceOrderItem => {
        const ecommerceOrderItem: EcommerceOrderItem = {
            item_id: orderItem.product_sku,
            item_name: orderItem.product_name,
            affiliation: '', // always empty?
            coupon: '', // never used on a product
            currency: orderItem.product_sale_price.currency,
            discount: 0, // TODO
            index: 0, // TODO
            // @ts-ignore
            item_brand: normalizeValue(orderItem.mpbrand?.value),
            ...getEcommerceItemCategories(orderItem),
            // @ts-ignore
            item_list_id: normalizeValue(orderItem?.item_list?.item_list_id),
            // @ts-ignore
            item_list_name: normalizeValue(orderItem?.item_list?.item_list_name),
            // @ts-ignore
            creative_name: normalizeValue(orderItem?.promotion?.creative_name),
            // @ts-ignore
            creative_slot: normalizeValue(orderItem?.promotion?.creative_slot),
            // @ts-ignore
            promotion_id: normalizeValue(orderItem?.promotion?.promotion_id),
            // @ts-ignore
            promotion_name: normalizeValue(orderItem?.promotion?.promotion_name),
            item_variant: '', // always empty?
            location_id: '', // always empty?
            price: formatPrice(orderItem.product_sale_price.value),
            quantity: orderItem.quantity_ordered,
        };

        return ecommerceOrderItem;
    }

    const getStandardEcommerceCartPayload = (cart: Cart) => {
        const items = cart.items.map(item => getCartEventItemFromProduct(item.product, item.quantity, item.promotion, item.item_list));
        const value = cart.prices?.grand_total.value;
        const coupon = cart.applied_coupons?.map(coupon => coupon.code).join("|");

        const payload: {
            currency: string,
            value: number,
            items: EcommerceCartItem[],
            coupon?: string
        } = {
            currency: "EUR",
            value: formatPrice(value),
            items,
        };

        if (coupon !== undefined && coupon.length > 0)
            payload.coupon = coupon;

        return payload;
    }

    /**
     * Sends an ecommerce event to GTM
     *
     * @param event
     * @param ecommerce
     */
    const sendEcommerceEvent = (event: string, ecommerce: any) => {
        sendEvent({ ecommerce: null }); // Clear the previous ecommerce object.
        sendEvent({
            event,
            ecommerce,
        });
    }

    /**
     * Inject the GTM/gtag scripts in the head
     */
    const injectScripts = () => {
        scriptsInjected.value = true;
        scripts.forEach(script => {
            const el = document.createElement("script");
            el.setAttribute("data-hid", script.hid);
            el.setAttribute("name", script.name);
            el.setAttribute("type", script.type);
            el.innerHTML = script.innerHTML;
            document.head.appendChild(el);
        });
    }

    /**
     * Adds events to the dataLayer, or to the backlog if we have to wait for the init event or other events to fire
     * first.
     *
     * @param payload
     * @param skipBacklogCheck
     */
    const sendEvent = (payload: any, skipBacklogCheck: boolean = false) => {
        window.dataLayer = window.dataLayer || [];

        // If skipping the backlog check, or if the backlog is empty, add the event to the datalayer
        if (skipBacklogCheck || eventsBacklog.value.length === 0) {
            window.dataLayer.push(structuredClone(payload));
            // Only inject the scripts after we add the init event to the dataLayer, requested by Rocket
            if (payload?.event === "init" && !scriptsInjected.value)
                injectScripts();
            return;
        }

        if (payload?.event === "init") {
            // If this is an init event, replace the first instance of a string starting with "INIT" in the backlog with
            // the event, or adds to the end if it doesn't exist
            const eventsBacklogIndex = eventsBacklog.value.findIndex(
                event => typeof event === "string" && event.startsWith("INIT")
            );
            if (eventsBacklogIndex >= 0)
                eventsBacklog.value.splice(eventsBacklogIndex, 1, payload);
            else eventsBacklog.value.push(payload);
        } else
            // Otherwise, just add to the back of the backlog
            eventsBacklog.value.push(payload);
    }

    /**
     * Sends the add_to_cart GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_or_remove_an_item_from_a_shopping_cart
     *
     * @param product
     * @param quantity
     */
    const sendAddToCartEvent = (
        product: ProductInterface,
        quantity: number,
        promotion?: PromotionInterface,
        itemList?: ItemListInterface,
    ) => {
        const item = getCartEventItemFromProduct(product, quantity, promotion);
        const value = formatPrice(item.price * quantity);

        let _itemList = itemList ?? currentPageListItem.value;
        if (_itemList) {
            const {
                item_list_name,
                item_list_id,
            } = _itemList;
            item.item_list_name = item_list_name;
            item.item_list_id = item_list_id;
        }

        const payload = {
            currency: "EUR",
            value,
            items: [item],
        };

        sendEcommerceEvent("add_to_cart", payload)
    }

    /**
     * Sends the remove_from_cart GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_or_remove_an_item_from_a_shopping_cart
     *
     * @param product
     * @param quantity
     */
    const sendRemoveFromCartEvent = (
        product: ProductInterface,
        quantity: number,
        promotion?: PromotionInterface,
        itemList?: ItemListInterface,
    ) => {
        const item = getCartEventItemFromProduct(product, quantity, promotion);
        const value = formatPrice(item.price * quantity);

        if (itemList) {
            const {
                item_list_name,
                item_list_id,
            } = itemList;
            item.item_list_name = item_list_name;
            item.item_list_id = item_list_id;
        }

        const payload = {
            currency: "EUR",
            value,
            items: [item],
        };

        sendEcommerceEvent("remove_from_cart", payload)
    }

    /**
     * Sends the initiate checkout GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#initiate_the_checkout_process
     *
     * @param cart
     */
    const sendInitiateCheckoutEvent = (cart: Cart) => {
        const payload = getStandardEcommerceCartPayload(cart);

        if (DEBUG_GTM.value) console.debug("INIT CHECK", structuredClone(cart));

        sendEcommerceEvent("begin_checkout", payload);
    }

    /**
     * Sends the add shipping info event when a user clicks on continue after selecting a shipping method on /checkout/shipping
     *
     * @param cart
     */
    const sendAddShippingInfoEvent = (cart: Cart, shippingTier: string) => {
        const payload = getStandardEcommerceCartPayload(cart);

        // @ts-ignore
        payload.shipping_tier = normalizeValue(shippingTier).toLowerCase();

        sendEcommerceEvent("add_shipping_info", payload);
    }

    /**
     * Sends the add billing info event when a user clicks on continue after selecting an invoice method on /checkout/billing
     *
     * @param cart
     * @param billingMethod
     */
    const sendAddBillingInfoEvent = (cart: Cart, billingMethod: string) => {
        const payload = getStandardEcommerceCartPayload(cart);

        // @ts-ignore
        payload.billing_type = normalizeValue(billingMethod).toLowerCase();

        sendEcommerceEvent("add_billing_info", payload);
    }

    /**
     * Sends the add payment info event when a user selects a payment type on /checkout/payment.
     *
     * @param cart
     * @param billingMethod
     */
    const sendAddPaymentInfoEvent = (cart: Cart, paymentType: string) => {
        const payload = getStandardEcommerceCartPayload(cart);

        // @ts-ignore
        payload.payment_type = normalizeValue(paymentType).toLowerCase();

        sendEcommerceEvent("add_payment_info", payload);
    }

    /**
     * Sends the purchase GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#initiate_the_checkout_process
     *
     * @param order
     */
    const sendPurchaseEvent = (order) => {
        if (!order?.id) {
            console.warn("Failed to send purchase event, order is invalid.")
            return;
        }

        if (DEBUG_GTM.value) console.debug("PURCHASE EVENT ORDER", order);

        const items = order.items.map(item => getEcommerceOrderItemFromOrderItem(item));

        // Amount that has been refunded? (and what about adjustments?)
        // const creditMemoSubtotalTotal = order.credit_memos.reduce((total, credit_memo) => total + credit_memo.total.subtotal.value, 0)

        const {
            number,
            applied_coupon,
            shipping_method_code,
            payment_methods,
        } = order;

        if (payment_methods.length !== 1)
            console.warn(`Expected 1 payment method, instead got ${payment_methods.length}.`);

        const payment_method = payment_methods.length > 0 ? payment_methods[0].type : null;

        const payload: {
            transaction_id: string,
            value: number,
            tax: number,
            shipping: number,
            currency: string,
            coupon: string,
            affiliation: string,
            payment_type: string,
            shipping_tier: string,
            items: EcommerceCartItem[],
        } = {
            transaction_id: normalizeValue(number).toLowerCase(),
            // Amount that was ultimately paid
            value: formatPrice(order.total.base_grand_total.value),
            tax: formatPrice(order.total.total_tax.value),
            shipping: formatPrice(order.total.total_shipping.value),
            currency: order.total.subtotal.currency,
            coupon: normalizeValue(applied_coupon).toUpperCase(),
            affiliation: '', // always empty?
            payment_type: normalizeValue(payment_method).toLowerCase(),
            shipping_tier: normalizeValue(shipping_method_code).toLowerCase(),
            items: items,
        };

        sendEcommerceEvent("purchase", payload);
    }

    /**
     * Sends the select item GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_an_item_from_a_list
     *
     * @param product
     */
    const sendSelectItemEvent = (product: ProductInterface) => {
        const item = getProductItemFromProduct(product, 1);
        const value = formatPrice(item.price);
        const promotionOptions = getPromotionFromLocation() ?? {};

        newPageListItem.value = {
            item_list_name: product.item_list_name,
            item_list_id: product.item_list_id,
        }

        const payload = {
            currency: "EUR",
            value,
            items: [
                {
                    ...item,
                    ...promotionOptions,
                }
            ],
        };

        sendEcommerceEvent("select_item", payload);
    }

    /**
     * Sends the view item GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_an_item_from_a_list
     *
     * @param product
     */
    const sendViewItemEvent = (product: ProductInterface) => {
        const item = getProductItemFromProduct(product, 1, null, currentPageListItem.value);
        const value = formatPrice(item.price);
        const promotionOptions = getPromotionFromLocation() ?? {};

        const payload = {
            currency: "EUR",
            value,
            items: [
                {
                    ...item,
                    ...promotionOptions
                }
            ],
        };

        sendEcommerceEvent("view_item", payload);
    }

    /**
     * Sends the select promotion GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#apply_promotions
     *
     * @param product
     */
    const sendSelectPromotionEvent = (promotion: PromotionInterface) => {
        const {
            creative_name,
            creative_slot,
            promotion_name,
            promotion_id,
        } = promotion;

        const payload = {
            items: [
                {
                    creative_name,
                    creative_slot,
                    promotion_name,
                    promotion_id,
                }
            ],
        };

        sendEcommerceEvent("select_promotion", payload);
    }

    /**
     * Sends the view promotion GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#apply_promotions
     *
     * @param product
     */
    const sendViewPromotionEvent = (promotion: PromotionInterface) => {
        const {
            creative_name,
            creative_slot,
            promotion_name,
            promotion_id,
        } = promotion;

        const payload = {
            items: [
                {
                    creative_name,
                    creative_slot,
                    promotion_name,
                    promotion_id,
                }
            ],
        };

        sendEcommerceEvent("view_promotion", payload);
    }

    /**
     * Sends the view item list GTM event
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_an_item_from_a_list
     *
     * @param item_list_id
     * @param item_list_name
     * @param products
     */
    const sendViewItemListEvent = (item_list_id: string, item_list_name: string, products: ProductInterface[], categoryName?: string) => {
        const items = products.map(product => {
            if (categoryName && product.categories.map(category => category.name).indexOf(categoryName) !== -1)
                return getProductItemFromProduct(product, 1, categoryName);

            return getProductItemFromProduct(product, 1);
        });

        const payload = {
            item_list_id,
            item_list_name,
            items,
        };

        sendEcommerceEvent("view_item_list", payload);
    }

    /**
     * Always do init event, only when init event is on the thankyou(purchase) page then make init event using order
     * data. If purchase has been made by a guest then only fill address- and base user data. When purchase is made
     * by logged in customer then add more fields because we have more information like customer id and last order
     * placed.
     *
     * @param user
     * @param order
     */
    const getCustomerEventData = (user: Customer | null, order = null) => {
        if (!order) {
            const {
                created_at,
                customer_id,
                email,
                orders_summary,
            } = user || {};

            const address = user?.addresses?.find(address => address.default_billing);
            const {
                street,
                country_code,
                city,
                region,
                telephone,
                postcode,
            } = address || {};

            const {
                region_code,
            } = region || {};

            const {
                order_first_date,
                order_last_date,
                order_last_value,
                order_total,
                order_total_value,
            } = orders_summary || {};

            const createdAtFormatted = created_at ? formatDate(created_at) : "";

            return {
                user_signup_date: normalizeValue(createdAtFormatted).toLowerCase(), // e.g. '19/12/2023'
                user_id: normalizeValue(customer_id).toLowerCase(), // e.g. '123456'
                user_address: normalizeValue(street?.join(" ").toLowerCase()), // e.g. 'test street 11'
                user_first_name: normalizeValue(user?.firstname ?? '').toLowerCase(),
                user_last_name: normalizeValue(user?.lastname ?? '').toLowerCase(),
                user_country: normalizeValue(country_code).toLowerCase(), // e.g. 'it'
                user_city: normalizeValue(city).toLowerCase(), // e.g. 'roma'
                user_province: normalizeValue(region_code).toLowerCase(), // e.g. 'rm'
                user_phone: normalizeValue(telephone).toLowerCase(), // e.g. '3333333333'
                user_email: normalizeValue(email).toLowerCase(), // e.g. 'email@email.it',
                user_postalcode: normalizeValue(postcode).toLowerCase(), // e.g. '00100'
                order_first_date: normalizeValue(order_first_date).toLowerCase(), // e.g. '21/12/2023',
                order_last_date: normalizeValue(order_last_date).toLowerCase(), // e.g. '21/12/2023',
                order_last_value: order_last_value ? formatPrice(order_last_value) : '', // e.g. '100.99',
                order_total: order_total ? formatPrice(order_total) : '', // e.g. '2',
                order_total_value: order_total_value ? formatPrice(order_total_value) : '', // e.g. '250.88'
                is_new_customer: (!(order_total !== null && formatPrice(order_total) > 0)), // e.g. 'false'
            }
        } else if (order) {
            let userData = {};

            // always fill in this data as guest and customer both have this data
            let purchasePageInitData = {
                user_address: normalizeValue(order.address).toLowerCase(), // e.g. 'test street 11'
                user_first_name: normalizeValue(order.billing_address?.firstname ?? '').toLowerCase(),
                user_last_name: normalizeValue(order.billing_address?.lastname ?? '').toLowerCase(),
                user_country: normalizeValue(order.country).toLowerCase(), // e.g. 'it'
                user_city: normalizeValue(order.city).toLowerCase(), // e.g. 'roma'
                user_province: normalizeValue(order.region_code).toLowerCase(), // e.g. 'rm'
                user_phone: normalizeValue(order.telephone).toLowerCase(), // e.g. '3333333333'
                user_email: normalizeValue(order.email).toLowerCase(), // e.g. 'email@email.it',
                user_postalcode: normalizeValue(order.postcode).toLowerCase(), // e.g. '00100'
                is_new_customer: order.is_new_customer // e.g. 'false'
            }

            if (user) {
                const {
                    created_at,
                    customer_id,
                    orders_summary,
                } = user || {};

                const createdAtFormatted = created_at ? formatDate(created_at) : "";

                const {
                    order_first_date,
                    order_last_date,
                    order_last_value,
                    order_total,
                    order_total_value,
                } = orders_summary || {};

                userData = {
                    user_signup_date: normalizeValue(createdAtFormatted).toLowerCase(), // e.g. '19/12/2023'
                    user_id: normalizeValue(customer_id).toLowerCase(), // e.g. '123456'
                    order_first_date: normalizeValue(order_first_date).toLowerCase(), // e.g. '21/12/2023',
                    order_last_date: normalizeValue(order_last_date).toLowerCase(), // e.g. '21/12/2023',
                    order_last_value: order_last_value ? formatPrice(order_last_value) : '', // e.g. '100.99',
                    order_total: order_total ? formatPrice(order_total) : '', // e.g. '2',
                    order_total_value: order_total_value ? formatPrice(order_total_value) : '', // e.g. '250.88'
                }
            }

            return {
                ...purchasePageInitData,
                ...userData
            };
        }
    }

    /**
     * Sends the init event, which is on page load
     *
     * @param user
     * @param order
     */
    const sendInitEvent = (user: Customer | null, order = null) => {
        const payload = {
            event: "init",
            ...getCustomerEventData(user, order),
        };

        sendEvent(payload);
    }

    /**
     * Sends newsletter signup event, when someone correctly subscribes to the newsletter
     *
     * @param email
     */
    const sendNewsletterSignupEvent = (email: string) => {
        if (!email) return;
        sendEvent({
            event: "newsletter-signup",
            user_email: normalizeValue(email).toLowerCase(),
        });
    }

    /**
     * Sends view category event, when someone visits a POP page
     *
     * @param category
     */
    const sendViewCategoryEvent = (category: string) => {
        if (!category) return;
        sendEvent({
            event: "view_category",
            category: normalizeValue(category).toLowerCase(),
            category2: "",
            category3: "",
            category4: "",
            category5: ""
        });
    }

    /**
     * Sends signup event, when someone signs up
     *
     * @param user
     */
    const sendSignUpEvent = (user: Customer) => {
        if (!user) return;

        sendEvent({
            event: "sign_up",
            ...getCustomerEventData(user),
        });
    }

    /**
     * Sends login event, when someone logs in or gets logged in automatically after registering
     *
     * @param user
     */
    const sendLoginEvent = (user: Customer) => {
        if (!user) return;

        sendEvent({
            event: "login",
            ...getCustomerEventData(user),
        });
    }

    /**
     * Gets the creative/promotion id/name from the current URL and returns it as an object that can be used.
     */
    const getPromotionFromLocation = () => {
        const url = new URL(location.href);
        const creativeName = url.searchParams.get("creative_name");
        const creativeSlot = url.searchParams.get("creative_slot");
        const promotionName = url.searchParams.get("promotion_name");
        const promotionId = url.searchParams.get("promotion_id");
        if (creativeName && creativeSlot  && promotionName && promotionId) {
            return {
                creative_name: creativeName,
                creative_slot: creativeSlot,
                promotion_name: promotionName,
                promotion_id: promotionId,
            }
        } else return null;
    }

    /**
     * Gets the item list id/name from the provided product, or from the current page if product doesn't have it.
     */
    const getItemListFromProduct = (product: ProductInterface) => {
        let {
            item_list_name,
            item_list_id,
        } = product;
        if (item_list_name && item_list_id)
            return {
                item_list_name,
                item_list_id,
            }
        if (currentPageListItem.value) {
            ({
                item_list_name,
                item_list_id,
            } = currentPageListItem.value);
            if (item_list_name && item_list_id) {
                return {
                    item_list_name,
                    item_list_id,
                }
            }
        }

        return null;
    }

    /**
     * Sends search event
     */
    const sendSearchEvent = (term: string, result: string) => {
        sendEvent({
            event: "search",
            search_term: term,
            result: result
        });
    }


    const methodsToExport = {
        waitForInit,
        cleanupInitEvent,
        sendAddToCartEvent,
        sendRemoveFromCartEvent,
        sendInitiateCheckoutEvent,
        sendSelectPromotionEvent,
        sendViewPromotionEvent,
        sendSelectItemEvent,
        sendViewItemEvent,
        sendViewItemListEvent,
        sendPurchaseEvent,
        sendInitEvent,
        sendNewsletterSignupEvent,
        sendViewCategoryEvent,
        sendSignUpEvent,
        sendLoginEvent,
        sendAddShippingInfoEvent,
        sendAddBillingInfoEvent,
        sendAddPaymentInfoEvent,
        getPromotionFromLocation,
        getItemListFromProduct,
        sendSearchEvent,
    };

    // Wraps all methods in a try-catch, so if they fail for whatever reason, they don't crash a checkout page for example.
    // @ts-ignore
    const safeMethods = Object.fromEntries(Object.entries(methodsToExport).map(([methodName, method]) => {
        const safeMethod = (...args) => {
            try {
                // @ts-ignore
                return method(...args);
            } catch (error) {
                console.error(`Caught an error in GTM event method ${methodName}.`, error);
            }
        };

        return [methodName, safeMethod];
    })) as typeof methodsToExport;

    return {
        ...safeMethods,
        eventsBacklog,
        eventsBacklogLock,
    }
});
